import {
  axiosInstance as axios,
  axiosInstance2 as axiosV2,
} from "@/core/services/api.client";
import { AddedLeadCommentSpec } from "@/projects/client/modules/rep/services/RepService";
import store from "@/store";
import { computed } from "vue";
const clientPrefix = "api/v1/client/";

const ibPrefix = "api/v1/ib/";

const salesPrefix = computed(
  () => `api/v1/sales/${store.state.SalesModule.salesAccount.uid}/`
);

const salesService = {
  updateIbDefaultCode: async (code: string) =>
    (
      await axios.put(
        salesPrefix.value + "referral/code/" + code + "/default-client"
      )
    ).data,

  queryAgentClientReferralHistory: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "referral/user-history", {
        params: criteria,
      })
    ).data,
  updateIbLink: async (id: number, formData: any) =>
    (await axios.put(salesPrefix.value + "referral/code/" + id, formData)).data,

  checkReferCode: async (referCode: string) =>
    (await axios.get("api/v1/" + "referralCode/" + referCode)).data,

  queryAccountStat: async (childUid, criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "account/" + childUid + "/stat", {
        params: criteria,
      })
    ).data,
  getReferalPath: async (uid: number) =>
    (await axios.get(salesPrefix.value + "account/referralPath/" + uid)).data,

  getAccountDetailByUid: async (accountUid: number) =>
    (await axios.get(salesPrefix.value + "account/" + accountUid)).data,

  openTradeAccount: async (accountUid: number, formData: any) =>
    (
      await axios.post(
        salesPrefix.value +
          "application/for-user/" +
          accountUid +
          "/trade-account",
        formData
      )
    ).data,
  getIBAccountConfiguration: async (agentUid: number) =>
    (await axios.get(salesPrefix.value + "account/configuration/" + agentUid))
      .data,

  getAccountDefaultLevelSetting: async (agentUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "account/" + agentUid + "/default-level-setting"
      )
    ).data,

  getIbStat: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "account/child/stat", {
        params: criteria,
      })
    ).data,
  getIbRebateStat: async (criteria?: any) =>
    (
      await axios.get(
        salesPrefix.value + "account/child/stat/rebate/symbol-grouped",
        { params: criteria }
      )
    ).data,

  getSalesRebateStat: async (criteria?: any) =>
    (
      await axios.get(
        salesPrefix.value + "account/child/stat/trade/symbol-grouped",
        { params: criteria }
      )
    ).data,
  getDefaultLevelSetting: async () =>
    (await axios.get(salesPrefix.value + "rebate-rule/default-level-setting"))
      .data,

  getAvailableAccountTypes: async () =>
    (
      await axios.get(
        salesPrefix.value + "account/configuration/account-type-available"
      )
    ).data,

  getRebateRuleDetailByUid: async (agentUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "rebate-rule/agent/" + agentUid + "/detail"
      )
    ).data,

  getRebateRuleRemain: async (agentUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "rebate-rule/agent/" + agentUid + "/remain"
      )
    ).data,

  getIBRebateRule: async (uid: any) =>
    (await axios.get(salesPrefix.value + "rebate-rule/agent/" + uid)).data,

  putIBRebateRule: async (agentUid: any, id: any, formData: any) =>
    (
      await axios.put(
        salesPrefix.value + "rebate-rule/agent/" + agentUid + "/" + id,
        formData
      )
    ).data,

  putTopIBRebateRule: async (agentUid: any, id: any, formData: any) =>
    (
      await axios.put(
        salesPrefix.value + "rebate-rule/top-agent/" + agentUid + "/" + id,
        formData
      )
    ).data,

  // ==========================================

  postIbReferralAgentLinkBySales: async (agentUid: number, formData: any) =>
    (
      await axios.post(
        salesPrefix.value + "referral/agent/" + agentUid + "/agent",
        formData
      )
    ).data,

  postIbReferralClientLinkBySales: async (agentUid: number, formData: any) =>
    (
      await axios.post(
        salesPrefix.value + "referral/agent/" + agentUid + "/client",
        formData
      )
    ).data,

  getLevelAccounts: async (childUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "account/" + childUid + "/level-account"
      )
    ).data,

  getAgentRules: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "rebate-rule/agent", {
        params: criteria,
      })
    ).data,

  getIbRebateRuleDetailFromSales: async (agentUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "rebate-rule/agent/" + agentUid + "/detail"
      )
    ).data,

  getIbRebateRuleRemainFromSales: async (agentUid: number) =>
    (
      await axios.get(
        salesPrefix.value + "rebate-rule/agent/" + agentUid + "/remain"
      )
    ).data,

  getCategory: async () =>
    (await axios.get(clientPrefix + "rebate/symbol/category")).data,

  getSymbolsList: async () =>
    (await axios.get(clientPrefix + "rebate-direct-schema/symbol/all")).data,

  queryAccounts: async (criteria?: any) =>
    (await axios.get(salesPrefix.value + "account", { params: criteria })).data,

  getIbLinks: async (criteria?: any) =>
    (await axios.get(salesPrefix.value + "referral", { params: criteria }))
      .data,

  postIbLink: async (formData: any) =>
    (await axios.post(salesPrefix.value + "referral", formData)).data,

  getSalesLinkDetail: async (code: any) =>
    (await axios.get(salesPrefix.value + "referral/code/" + code)).data,

  postSalesLinkForIB: async (formData: any) =>
    (await axios.post(salesPrefix.value + "referral/top-agent", formData)).data,

  postSalesLinkForClient: async (formData: any) =>
    (await axios.post(salesPrefix.value + "referral/client", formData)).data,

  getIbLinkDetail: async (code: any) =>
    (await axios.get(salesPrefix.value + "referral/" + code)).data,

  queryTradeReportsOfSales: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "tradetransaction", {
        params: criteria,
      })
    ).data,

  queryTransactionReportsOfSales: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "transaction", {
        params: criteria,
      })
    ).data,

  fuzzySearchAccount: async (criteria?: any) =>
    (
      await axios.get(salesPrefix.value + "search/account", {
        params: criteria,
      })
    ).data,

  queryClientTransaction: async (accountUid: number, criteria?: any) =>
    (
      await axios.get(
        salesPrefix.value + "trade-account/" + accountUid + "/transaction",
        { params: criteria }
      )
    ).data,

  queryClientTrade: async (accountUid: number, criteria?: any) =>
    (
      await axios.get(
        salesPrefix.value + "trade-account/" + accountUid + "/trade",
        { params: criteria }
      )
    ).data,

  queryClientDeposit: async (criteria?: any) =>
    (await axios.get(salesPrefix.value + "deposit", { params: criteria })).data,

  queryClientWithdrawal: async (criteria?: any) =>
    (await axios.get(salesPrefix.value + "withdrawal", { params: criteria }))
      .data,

  querySalesLeads: async (criteria?: any) =>
    (await axios.get(salesPrefix.value + "lead", { params: criteria })).data,

  addCommentToLead: async (
    leadId: number,
    addedLeadCommentSpec: AddedLeadCommentSpec
  ) =>
    (
      await axios.post(
        salesPrefix.value + "lead/" + leadId + "/comment",
        addedLeadCommentSpec
      )
    ).data,

  getLeadDetails: async (leadId: number) =>
    (await axios.get(salesPrefix.value + "lead/" + leadId)).data,
};

export default salesService;
