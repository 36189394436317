import { axiosInstance as axios } from "@/core/services/api.client";
import { computed } from "vue";
import store from "@/store";
import { TimeZoneService } from "@/core/plugins/TimerService";

const agentAccount = computed(() => store.state.AgentModule.agentAccount);
const agentPrefix = computed(() => `api/v1/ib/${agentAccount.value?.uid}/`);
export default {
  getRebateTodayValue: async (timezoneOffset?: number) =>
    (
      await axios.get(`${agentPrefix.value}report/rebate/today-value`, {
        params: { timezoneOffset },
      })
    ).data,

  getRebateTotalValue: async (criteria?: any) =>
    (
      await axios.get(`${agentPrefix.value}report/rebate/total-value`, {
        params: criteria,
      })
    ).data,

  getRebateDailySeries: async (timezoneOffset?: number) =>
    (
      await axios.get(`${agentPrefix.value}report/rebate/daily`, {
        params: { timezoneOffset },
      })
    ).data,

  getRebateHourlySeries: async (timezoneOffset?: number) =>
    (
      await axios.get(`${agentPrefix.value}report/rebate/hourly`, {
        params: { timezoneOffset },
      })
    ).data,

  getRebateMonthlySeries: async (timezoneOffset?: number) =>
    (
      await axios.get(`${agentPrefix.value}report/rebate/monthly`, {
        params: { timezoneOffset },
      })
    ).data,

  getTradeTodayVolume: async () =>
    (
      await axios.get(`${agentPrefix.value}report/trade/today-volume`, {
        params: { timezoneOffset: TimeZoneService.getTimeZoneOffsetInHours() },
      })
    ).data,

  getTradeSymbolTodayVolume: async () =>
    (
      await axios.get(`${agentPrefix.value}report/trade/today-symbol-volume`, {
        params: { timezoneOffset: TimeZoneService.getTimeZoneOffsetInHours() },
      })
    ).data,

  getLatestDeposits: async (count?: number) =>
    (
      await axios.get(`${agentPrefix.value}report/deposit/latest`, {
        params: { count },
      })
    ).data,

  getTodayAccountCreationCount: async () =>
    (await axios.get(`${agentPrefix.value}report/account/today-creation`)).data,

  getDepositTodayValue: async () =>
    (await axios.get(`${agentPrefix.value}report/deposit/today-value`)).data,
};
