import ClientLayout from "@/layouts/client-layout/ClientLayout.vue";
import Index from "./views/SupportsIndex.vue";
// import SupportsCalendar from "./views/SupportsCalendar.vue";
import SupportsNotices from "./views/SupportsNotices.vue";
// import SupportsNews from "./views/SupportsNews.vue";
import DocumentsIndex from "./views/DocumentsIndex.vue";
import CaseIndexVue from "./views/CaseIndex.vue";
export default (router) => {
  router.addRoute({
    path: "/supports",
    redirect: "/supports",
    component: ClientLayout,
    name: "supports",
    children: [
      {
        path: "/supports",
        name: "SupportsIndex",
        component: Index,
        meta: {
          pageTitle: "title.supports",
          breadcrumbs: ["title.supports", "title.contactUs"],
          permissions: ["any"],
        },
      },
      {
        path: "/supports/notices",
        name: "SupportsNotices",
        component: SupportsNotices,
        meta: {
          pageTitle: "title.supportsNotices",
          breadcrumbs: ["title.supports", "title.supportsNotices"],
          permissions: ["any"],
        },
      },
      {
        path: "/supports/documents",
        name: "DocumentIndex",
        component: DocumentsIndex,
        meta: {
          pageTitle: "title.documents",
          breadcrumbs: ["title.supports", "title.documents"],
          permissions: ["any"],
        },
      },
      {
        path: "/supports/cases",
        name: "CaseIndex",
        component: CaseIndexVue,
        meta: {
          pageTitle: "title.cases",
          breadcrumbs: ["title.supports", "title.cases"],
          permissions: ["TenantAdmin"],
        },
      },
    ],
  });
};
