enum Actions {
  PUT_IB_CURRENT_ACCOUNT = "putIBCurrentAccount",
  PUT_IB_ACCOUNTS = "putIBAccounts",
  GET_IB_ACCOUNT_LIST = "getIBAccountList",
}
enum Mutations {
  SET_IB_CURRENT_ACCOUNT = "setIBCurrentAccount",
  SET_IB_ACCOUNTS = "setIBAccounts",
}

export { Actions, Mutations };
