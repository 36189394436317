export default {
  "1": {
    id: 1,
    answer: "b",
  },
  "2": {
    id: 2,
    answer: "b",
  },
  "3": {
    id: 3,
    answer: "b",
  },
  "4": {
    id: 4,
    answer: "b",
  },
  "5": {
    id: 5,
    answer: "b",
  },
  "6": {
    id: 6,
    answer: "b",
  },
  "7": {
    id: 7,
    answer: "b",
  },
  "8": {
    id: 8,
    answer: "b",
  },
  "9": {
    id: 9,
    answer: "b",
  },
  "10": {
    id: 10,
    answer: "b",
  },
  "11": {
    id: 11,
    answer: "b",
  },
  "12": {
    id: 12,
    answer: "b",
  },
  "13": {
    id: 13,
    answer: "b",
  },
  "14": {
    id: 14,
    answer: "b",
  },
  "15": {
    id: 15,
    answer: "b",
  },
  "16": {
    id: 16,
    answer: "b",
  },
  "17": {
    id: 17,
    answer: "b",
  },
  "18": {
    id: 18,
    answer: "b",
  },
  "19": {
    id: 19,
    answer: "b",
  },
  "20": {
    id: 20,
    answer: "b",
  },
  "21": {
    id: 21,
    answer: "b",
  },
  "22": {
    id: 22,
    answer: "b",
  },
  "23": {
    id: 23,
    answer: "b",
  },
};
